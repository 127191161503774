<template>
    <div class="breadcrumb" v-if="breadcrumbList != null">
        <ul>
            <li
                v-for="(breadcrumb, idx) in breadcrumbList"
                :key="idx"
                @click="routeTo(idx)"
                :class="{ linked: !!breadcrumb.link }"
            >
                <template v-if="breadcrumb.name == 'Breadcrumb'">
                    {{ current }}
                </template>
                <template v-else>
                    {{ breadcrumb.name }}
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Breadcrumb',
    data() {
        return {
            breadcrumbList: [],
        }
    },
    props: {
        current: String,
    },
    mounted() {
        this.updateBreadCrumbList()
    },
    watch: {
        $route() {
            this.updateBreadCrumbList()
        },
    },
    methods: {
        routeTo(route) {
            if (this.breadcrumbList[route].link)
                this.$router.push(this.breadcrumbList[route].link)
        },
        updateBreadCrumbList() {
            this.breadcrumbList = this.$route.meta.breadcrumb
        },
    },
}
</script>

<style lang="scss" scoped>
@import './BreadCrumbs.scss';
</style>
